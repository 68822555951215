import { FC } from 'react';

import Head from 'nordic/head';

import { DocumentProps } from './types';

import Footer from '@app/components/footer';

const Wrapper = ({ children }) => {
  return <div className="alias">{children}</div>;
};

const Document: FC<DocumentProps> = ({
  meta_tags: metaTags,
  styles: styles,
  children,
  urlImage,
  footerUrl,
  textSRL,
  description,
}) => (
  <>
    <Wrapper>
      <Head>
        {metaTags.map(metaTag => (
          <meta
            key={metaTag.type}
            name={metaTag.type}
            content={metaTag.content}
          />
        ))}
        <meta
          property="og:description"
          content={description?.replace(/\s+/g, ' ')?.trim()}
        />
        {styles.map(style => (
          <link key={style} rel="stylesheet" href={style} />
        ))}
        <link
          rel="icon"
          href="https://http2.mlstatic.com/storage/mshops-appearance-api/resources/linkinbio/images/biolibre_favicon.png"
        />
      </Head>
      {children}
    </Wrapper>
    <div className="footer-document">
      <Footer urlImage={urlImage} footerUrl={footerUrl} textSRL={textSRL} />
    </div>
  </>
);

export default Document;

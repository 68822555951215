import { TrackObject, OptionsMelidataEvent } from "./typings";

export const gaTracker = (options: TrackObject) => {
  if (!window.meli_ga) return;
  try {
    window.meli_ga<TrackObject>('send', 'event', options);
  } catch {
    //TODO: Not report track
  }
}

export const melidataTracker = (options: OptionsMelidataEvent) => {
  if (!window.melidata) return;
  try{
    window.melidata<OptionsMelidataEvent>('send', 'event', options);
  }catch {}
}

export const KEYS_FOR_TRACKS = {
  Categories: {
    createLanding: 'CREATED_LANDING',
  },
  Actions: {
    goToMP: 'GO_TO_MP',
    goToShop: 'GO_TO_SHOP',
    goToShops: 'GO_TO_SHOPS',
    goToNetworks: 'GO_TO_SOCIAL_NETWORK',
    goToGeneral: 'GO_TO_GENERAL_LINK',
  },
  Labels: {
    paymentLink: 'PAYMENT_LINK_',
    itemPublish: 'ITEM_',
    link: 'MP_LINK'
  },
  Pages: {
    createLandingHome: '/CREATED_LANDING/HOME/',
    createLanding: '/created_landing/home/',
  }
}

import { Tag } from '@modules/metrics/domain/structure';
import restClient from '@services/client-instance';


const enum CLICK_ZONES {
  generallink = "generallink",
  shops = "shops",
  paymentlink = "paymentlink",
  rrsscontact = "rrsscontact",
  tulink = "tulink",
  publicacion = "publicacion",
  other = "otros",
};

export const ZONE_CLICKS_MAPPER = {
  "products-list": CLICK_ZONES.publicacion,
  "social-link": CLICK_ZONES.rrsscontact,
  "link-list": CLICK_ZONES.generallink,
  "shops": CLICK_ZONES.shops,
  "payment-link": CLICK_ZONES.paymentlink,
  "tu-link": CLICK_ZONES.tulink,
}

export const sendMetricClick = async (componentClickTags: Tag[], clientId: string) => {
  try {
    const { data } = await restClient.post('/metrics/metric-click', {
      data: {
        metric: {
          tags: componentClickTags,
          id: clientId,
        }
      },
    });
  } catch (error) {
    //TODO: handle error
  }
};

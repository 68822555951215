import { FC } from 'react';
import { useI18n } from 'nordic/i18n';

import YourLink from '@linkinbio-web-components/your-link';
import { YourLinkProps, YourLinkData } from './types';
import { captureTracking, redirectBlank } from '../render-tree/utils';

const YourLinkComponent: FC<YourLinkProps> = ({ data, ...props }) => {
  const { i18n } = useI18n();

  const handleClick = amount => {
    const { alias, site_id: site } = data as YourLinkData;

    captureTracking({ data: {} }, 'tu-link');
    redirectBlank(
      `/intention-pay?alias=${alias}&site=${site}&unit_price=${amount}&origin_device=LIB`
    );
  };

  return (
    <YourLink
      {...props}
      data={data}
      onPaymentClick={handleClick}
      mainMessage={i18n.gettext('Ingresa el monto')}
      buttonText={i18n.gettext('Elegir medio de pago')}
      mercadopagoSecureText={i18n.gettext('Pago seguro por Mercado Pago')}
      defaultTitle={i18n.gettext(`Enviar dinero a`)}
    />
  );
};

export default YourLinkComponent;

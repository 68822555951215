/* eslint-disable no-console */
import type { componentsList } from '@linkinbio-web-components/render-tree';

import AdditionalLink from '@linkinbio-web-components/additional-link-list';
import SocialList from '@linkinbio-web-components/social-link-list';
import Header from '@linkinbio-web-components/profile';
import ShopsProducts from '@linkinbio-web-components/product-list';
import MyShops from '@linkinbio-web-components/my-mshops';
import PaymentLinkList from '../wrapper-payment-link-list';

import Document from '../document';
import Layout from '../layout';
import UnknownComponent from '../unknown-component';
import YourLink from '../your-link'
import { captureTracking } from './utils';

export const SocialLinkList = (props) => {
  return <SocialList {...props} onTracking={(data) => captureTracking(data, "social-link", false)} />
}

export const ShopsProductsList = (props) => {
  return <ShopsProducts {...props} onTracking={(data) => captureTracking(data, "products-list", true)} />
}

export const LinkList = (props) => {
  return <AdditionalLink {...props} onTracking={(data) => captureTracking(data, "link-list", false)} />
}

export const MyMshops = (props) => {
  return <MyShops {...props} onClick={() => captureTracking({ data: props }, "shops", true)} />
}

const COMPONENT_LIST: componentsList = {
  nonEditables: {
    YourLink,
    Document,
    LinkList,
    SocialLinkList,
    PaymentLinkList,
    Layout,
    Header,
    ShopsProductsList,
    MyMshops
  },
  editables: {},
  defaultComponent: UnknownComponent,
};

export default COMPONENT_LIST;

import type { Request, Response } from 'express';

import RenderTreeWrapper from '@app/components/appearance/render-tree';

import { View } from '../NotFound/ui-components/view';

const AliasPage = ({ structure, translations }) =>
  structure?.appearance ? (
    <RenderTreeWrapper
      appearance={structure.appearance}
      profile={structure.linkin_bio}
    />
  ) : (
    <View translations={translations} />
  );

export const getServerSideProps = async (req: Request, res: Response) => {
  const { device, i18n, browser } = req;
  const { locals, structure: data } = res;

  return {
    props: {
      lowEnd: browser?.outdated,
      deviceType: device?.type,
      structure: data,
      translations: locals,
    },
    settings: {
      title: data['linkin_bio']?.alias
        ? `${data['linkin_bio']?.alias} | ${i18n?.gettext('Bio libre')}`
        : i18n?.gettext('Página no encontrada'),
      seller_id: data['linkin_bio']?.client_id || '',
      name: data['linkin_bio']?.alias || '',
    },
  };
};

export const setPageSettings = ({ settings }) => ({
  title: settings.title,
  className: 'alias lib-third-background-color',
  melidata: {
    path: '/created_landing/home',
    event_data: { seller_id: settings.seller_id },
  },
  navigation: {
    type: 'hidden',
  },
  layout: {
    staticMarkup: false,
  },
});

export default AliasPage;

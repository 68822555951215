import { FC, useEffect } from 'react';

import RenderTree from '@linkinbio-web-components/render-tree';

import COMPONENT_LIST from './component-list';

import { RenderTreeWrapperProps } from './types';

const RenderTreeWrapper: FC<RenderTreeWrapperProps> = ({ appearance }) => {

  useEffect(() => {
    const mshopStore = document.querySelector('.lib-my-mshops__container a');
    if (mshopStore) {
      mshopStore.addEventListener('click', (event) => {
        event.preventDefault();
      }, true)
    }
    const publications = document.querySelectorAll(".product-list__container a");
    if (publications) {
      publications.forEach(publication => {
        publication.addEventListener('click', (event) => {
          event.preventDefault();
        })
      })
    }
  }, []);

  return (
    <RenderTree
      componentsList={COMPONENT_LIST}
      node={appearance}
    />
  )
};

export default RenderTreeWrapper;

import PaymentLinkList from '@linkinbio-web-components/payment-link-list';
import { captureTracking } from '@app/components/appearance/render-tree/utils';

const PaymentLinkListWrapper = props => {
  const handleClick = item => {
    const { id, index } = item;
    captureTracking(
      { data: { id, index } },
      'payment-link'
    );
  };

  return <PaymentLinkList onClick={handleClick} {...props} />;
};

export default PaymentLinkListWrapper;

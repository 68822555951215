import {
  PARAMS_UTM_GO_TO_SHOP,
  PARAMS_UTM_PUBLICATION,
} from '@app/utils/constans';
import {
  sendMetricClick,
  ZONE_CLICKS_MAPPER,
} from '@app/utils/metrics/send-metrics';
import { gaTracker, melidataTracker } from '@app/utils/tracks';
import { KEYS_FOR_TRACKS } from '@app/utils/tracks/keys-constans-tracker';
import { TrackObject, OptionsMelidataEvent } from '@app/utils/tracks/typings';
import { Tag } from '@modules/metrics/domain/structure';
import { KeyComponents, Tracking } from '../types';

export const addNewsParams = ({
  url,
  newParams,
}: {
  url: string;
  newParams: string;
}): string => {
  if (url.includes('?')) return `${url}&${newParams}`;
  return `${url}?${newParams}`;
};

export const redirectBlank = (href: string) => {
  setTimeout(() => {
    const link = document.createElement('a');
    link.setAttribute('href', href);
    link.setAttribute('target', '_blank');
    link.click();
  });
};

const buildAndSendMelidata = (track: TrackObject) => {
  const path = track.path || track.page;

  const melidata: OptionsMelidataEvent = {
    path: `${path.toLowerCase()}${track.eventAction?.toLowerCase()}`,
    event_data: {
      event: {
        action: track.eventAction ? track.eventAction.toLowerCase() : '',
        category: track.eventCategory.toLowerCase(),
        label: track.eventLabel?.toLowerCase(),
      },
    },
  };
  melidataTracker(melidata);
};

const sendTrack = (dataTracking: Tracking, key: KeyComponents) => {
  const trackerObject: TrackObject = {
    eventCategory: KEYS_FOR_TRACKS.Categories.createLanding,
    page: KEYS_FOR_TRACKS.Pages.createLandingHome,
  };
  if (dataTracking.data) {
    switch (key) {
      case 'products-list':
        trackerObject.eventAction = KEYS_FOR_TRACKS.Actions.goToShops;
        trackerObject.eventLabel = `${KEYS_FOR_TRACKS.Labels.itemPublish}${
          dataTracking.index !== undefined ? dataTracking.index + 1 : 0
        }`;
        break;
      case 'social-link':
        trackerObject.eventAction = KEYS_FOR_TRACKS.Actions.goToNetworks;
        trackerObject.eventLabel = dataTracking.data?.code.toUpperCase();
        break;
      case 'link-list':
        trackerObject.eventAction = KEYS_FOR_TRACKS.Actions.goToGeneral;
        trackerObject.eventLabel = `${
          dataTracking.index !== undefined ? dataTracking.index + 1 : 0
        }`;
        break;
      case 'payment-link':
        trackerObject.eventAction = KEYS_FOR_TRACKS.Actions.goToMP;
        trackerObject.eventLabel = `${KEYS_FOR_TRACKS.Labels.paymentLink}${
          dataTracking.data?.index !== undefined
            ? dataTracking.data?.index + 1
            : 0
        }`;
        trackerObject.path = KEYS_FOR_TRACKS.Pages.createLanding;
        break;
      case 'tu-link':
        trackerObject.eventAction = KEYS_FOR_TRACKS.Actions.goToMP;
        trackerObject.eventLabel = KEYS_FOR_TRACKS.Labels.link;
        trackerObject.path = KEYS_FOR_TRACKS.Pages.createLanding;
        break;
      case 'shops': {
        trackerObject.eventAction = KEYS_FOR_TRACKS.Actions.goToShop;
      }
    }
  }
  if (trackerObject.eventAction) {
    // gaTracker(trackerObject);
    buildAndSendMelidata(trackerObject);
  }
};

export const captureTracking = async (
  dataTracking: Tracking,
  key: KeyComponents,
  shouldRedirectToBlank = false
) => {
  let href = '#';
  try {
    const tags: Tag[] = [];
    tags.push({
      key: 'type',
      value: ZONE_CLICKS_MAPPER[key],
    });

    if (dataTracking.data) {
      switch (key) {
        case 'products-list':
          tags.push({ key: 'itemid', value: dataTracking.data?.id });
          href = addNewsParams({
            url: dataTracking.data?.permalink,
            newParams: `${PARAMS_UTM_PUBLICATION}${
              dataTracking.index !== undefined ? dataTracking.index + 1 : 0
            }`,
          });
          break;
        case 'social-link':
          tags.push({
            key: 'rrss',
            value: dataTracking.data?.code.toLowerCase(),
          });
          href = dataTracking.data?.value;
          break;
        case 'link-list':
          tags.push(
            { key: 'title', value: dataTracking.data?.text },
            { key: 'itemgenerallink', value: dataTracking.data?.link }
          );
          href = dataTracking.data?.link;
          break;
        case 'payment-link':
          tags.push({ key: 'itempayid', value: dataTracking.data?.id });
          break;
        case 'shops':
          href = addNewsParams({
            newParams: PARAMS_UTM_GO_TO_SHOP,
            url: dataTracking.data?.src,
          });
          break;
      }
    }

    const aliasId = JSON.parse(
      document.getElementById('__PRELOADED_STATE__')?.textContent ?? '{}'
    ).pageState.structure.linkin_bio.client_id;
    /** Metrics of clicks */
    if (aliasId) {
      await sendMetricClick(tags, aliasId);
    }
    /** Tracks for Melidata and Google */
    sendTrack(dataTracking, key);
  } catch (error) {
    //TODO: Handle Error
    sendTrack(dataTracking, key);
  }
  if (shouldRedirectToBlank) {
    redirectBlank(href);
  }
};
